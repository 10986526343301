import React from "react"
import * as R from "ramda"
import { Link } from "gatsby"

import Lottie from "react-lottie"

import animation1 from "../images/left.json"
import animation2 from "../images/right.json"

import Hidden from "@material-ui/core/Hidden"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

import * as styles from "./animatedLifts.module.scss"

const AnimatedLifts = ({ pageContent }) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animation1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const defaultOptions3 = {
    loop: false,
    autoplay: true,
    animationData: animation2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const lifts = R.path(["lifts"], pageContent)

  return (
    <Box className={styles.animatedLiftsContainer}>
      <Grid container spacing={0} direction="row" justifyContent="center">
        {lifts.map((lift, index) => {
          if (index === 0) {
            return (
              <Grid item xs={12} md={6}>
                <div
                  className={styles.lottieLeft}
                  style={{ display: "block", textAlign: "center" }}
                >
                  <Lottie options={defaultOptions} height="100%" width="100%" />
                  <h2 className="animatedLift">{lift.title}</h2>
                  <Hidden smDown>
                    <p>{lift.text.text}</p>
                  </Hidden>
                  <Link to={`${lift.buttonLink}`}>
                    <Button variant="contained" color="primary">
                      {lift.buttonText}
                    </Button>
                  </Link>
                </div>
              </Grid>
            )
          } else {
            return (
              <Grid item xs={12} md={6}>
                <div
                  className={styles.lottieRight}
                  style={{ display: "block", textAlign: "center" }}
                >
                  <Lottie
                    options={defaultOptions3}
                    height="100%"
                    width="100%"
                  />
                  <h2 className="animatedLift">{lift.title}</h2>
                  <Hidden smDown>
                    <p>{lift.text.text}</p>
                  </Hidden>
                  <Link to={`${lift.buttonLink}`}>
                    <Button variant="contained" color="primary">
                      {lift.buttonText}
                    </Button>
                  </Link>
                </div>
              </Grid>
            )
          }
        })}
      </Grid>
    </Box>
  )
}

export default AnimatedLifts
