import React from "react"
import * as R from "ramda"
import { graphql, Link, useStaticQuery } from "gatsby"

import { BgImage } from "gbimage-bridge"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"

import Lift from "./lift"

import * as styles from "./liftList.module.scss"

const LiftList = ({ pageContent }) => {
  const {
    shape_lightGreen,
    shape_darkGreen,
    shape_yellow,
    shape_pink,
    shape_lightBlue,
  } = useStaticQuery(
    graphql`
      {
        shape_lightGreen: file(relativePath: { eq: "shape_lightGreen.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              layout: FULL_WIDTH
            )
          }
        }
        shape_darkGreen: file(relativePath: { eq: "shape_darkGreen.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              layout: FULL_WIDTH
            )
          }
        }
        shape_yellow: file(relativePath: { eq: "shape_yellow.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              layout: FULL_WIDTH
            )
          }
        }
        shape_pink: file(relativePath: { eq: "shape_pink.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              layout: FULL_WIDTH
            )
          }
        }
        shape_lightBlue: file(relativePath: { eq: "shape_lightBlue.png" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              layout: FULL_WIDTH
            )
          }
        }
      }
    `
  )

  const shapeLightGreen = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_lightGreen
  )
  const shapeDarkGreen = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_darkGreen
  )
  const shapeYellow = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_yellow
  )
  const shapePink = R.path(["childImageSharp", "gatsbyImageData"], shape_pink)
  const shapeLightBlue = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_lightBlue
  )

  const title = R.path(["title"], pageContent)
  const buttonAction = R.path(["buttonAction", "slug"], pageContent)
  const buttonText = R.path(["buttonText"], pageContent)
  const color = R.path(["color"], pageContent)
  const lifts = R.path(["liftList"], pageContent)

  // Gatsby background images seem to jump around during rendering. Static backgroundsize helps.
  // The following stuff helps to take care of this problem and defines two sizes for mobile/laptop.
  let shapeColor = ""
  let shapeSize = ""
  let shapeMinHeight = ""
  let titleMarginBottom = ""
  let btnColor = ""

  const theme = useTheme()
  const mobileSizeDetected = useMediaQuery(theme.breakpoints.down("sm"))

  shapeMinHeight = mobileSizeDetected ? "41px" : "190px"
  titleMarginBottom = mobileSizeDetected ? "-5px" : "20px"

  if (color === "Vihreä") {
    shapeColor = shapeLightGreen
    btnColor = "#55dc73"
    shapeSize = mobileSizeDetected ? "60px 41px" : "190px 129px"
  }
  if (color === "Tummanvihreä") {
    shapeColor = shapeDarkGreen
    btnColor = "#55dc73"
    shapeSize = mobileSizeDetected ? "62px 41px" : "190px 129px"
  }
  if (color === "Keltainen") {
    shapeColor = shapeYellow
    btnColor = "#fae600"
    shapeSize = mobileSizeDetected ? "62px 41px" : "193px 129px"
  }
  if (color === "Sininen") {
    shapeColor = shapeLightBlue
    btnColor = "#69d2fa"
    shapeSize = mobileSizeDetected ? "62px 41px" : "191px 128px"
  }
  if (color === "Pinkki") {
    shapeColor = shapePink
    btnColor = "#ff91d2"
    shapeSize = mobileSizeDetected ? "62px 41px" : "203px 135px"
  }

  return (
    <Box className={styles.liftList}>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12} md={12} lg={8}>
          <div
            className={styles.titlecontainer}
            style={{ marginLeft: "-70px" }}
          >
            <BgImage
              image={shapeColor}
              style={{
                minHeight: shapeMinHeight,
                backgroundSize: shapeSize,
                backgroundPositionX: "30px",
              }}
            >
              <h2
                className={styles.h2}
                style={{
                  wordBreak: "break-word",
                  position: "absolute",
                  bottom: "0px",
                  marginBottom: titleMarginBottom,
                }}
              >
                {title}
              </h2>
            </BgImage>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          className={styles.ctaButtonGrid}
          style={{ textAlign: "right", display: "flex" }}
        >
          <Link
            className={`${styles.ctabuttoncontainer}`}
            to={`/${buttonAction}`}
            style={{ marginBottom: "34px", backgroundColor: btnColor }}
          >
            <Button variant="contained" color="primary">
              {buttonText}
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={0} direction="row" className={styles.container}>
        <Lift liftContent={lifts} />
      </Grid>
    </Box>
  )
}

export default LiftList
