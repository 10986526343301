import React from "react"
import * as R from "ramda"

import ReactMarkdown from "react-markdown"

import { GatsbyImage } from "gatsby-plugin-image"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { Link } from "gatsby"
import * as styles from "./colorLift.module.scss"
import Button from "@material-ui/core/Button"
const ContentElement = ({ pageContent }) => {
  const backgroundColor = R.path(["backgroundColor"], pageContent)
  const title = R.path(["title"], pageContent)
  const contentText = R.path(["contentText", "contentText"], pageContent)
  const buttonText = R.path(["ctaButtonText"], pageContent)
  const url = R.path(["ctaButtonAction"], pageContent)
  const mainImage = R.path(["image", "gatsbyImageData"], pageContent)

  let bgColor = ""

  if (backgroundColor === "Vihreä") {
    bgColor = "#55dc73"
  }
  if (backgroundColor === "Keltainen") {
    bgColor = "#fae600"
  }
  if (backgroundColor === "Sininen") {
    bgColor = "#69d2fa"
  }
  if (backgroundColor === "Pinkki") {
    bgColor = "#ff91d2"
  }

  return (
    <Box className={styles.box} style={{ backgroundColor: bgColor }}>
      <Grid
        container
        direction="row"
        spacing={0}
        style={{
          width: "100%",
          maxWidth: "1200px",
          height: "100%",
          backgroundColor: "white",
        }}
      >
        <Grid item xs={12} md={6} className={styles.imageContainer}>
          <GatsbyImage image={mainImage} style={{ height: "100%" }} />
        </Grid>
        <Grid item xs={12} md={6} className={styles.textBox}>
          <h2 className="colorLiftTitle">{title}</h2>
          <ReactMarkdown children={contentText} softBreak="br" />
          {url && (
            <Link to={`${url}`}>
              <Button variant="contained" color="primary">
                {buttonText}
              </Button>
            </Link>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContentElement
