import React from "react"
import * as R from 'ramda'

import PictureLift from './pictureLift';

const getElement = (section) => {
  if (R.path(['__typename'], section) === 'ContentfulArtikkelisivu') {
    return (
      <PictureLift type="article" pageContent={section} key={R.path(['slug'], section)} />
    );
  }
  if (R.path(['__typename'], section) === 'ContentfulKohdesivu') {
    return (
      <PictureLift type="premise" pageContent={section} key={R.path(['slug'], section)} />
    );
  }
  if (R.path(['__typename'], section) === 'ContentfulKategoriasivu') {
    return (
      <PictureLift type="category" pageContent={section} key={R.path(['slug'], section)} />
    );
  }
};

const Lift = ({liftContent}) => {
  return liftContent ? liftContent.map(reference => getElement(reference)) : null;
}

export default Lift;