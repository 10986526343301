import React from "react"
import * as R from "ramda"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { useTheme } from "@material-ui/core/styles"
import * as styles from "./textElement.module.scss"

const TextElement = ({ pageContent }) => {
  const title = R.path(["title"], pageContent)
  const text = R.path(["text", "text"], pageContent)
  const ctaButtonSlug = R.path(["ctaButtonSlug"], pageContent)
  const buttonText = R.path(["ctaButtonText"], pageContent)
  const isWide = R.path(["wide"], pageContent)

  const theme = useTheme()

  return (
    <Box className={isWide ? styles.textElementWide : styles.textElement } component="section">
      <h2 className="colorLiftTitle">{title}</h2>
      <ReactMarkdown children={text} softBreak="br" />

      <div style={{ marginTop: "35px", textAlign: "center" }}>
        <Link to={`${ctaButtonSlug}`} className="blue">
          <Button variant="contained" color="primary">
            {buttonText}
          </Button>
        </Link>
      </div>
    </Box>
  )
}

export default TextElement
