import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import * as styles from "./articleCard.module.scss"

const ArticleCard = ({ slug, date, title, image, slugPrefix }) => {
  return (
    <Link className={styles.articleCard} to={`/${slugPrefix}/${slug}`}>
      <article>
        {image && (
          <GatsbyImage
            image={{ ...image.gatsbyImageData, aspectRatio: 1 / 1 }}
            className="w-full h-full"
          />
        )}
        <p className={`categoryText ${styles.date}`}>{date}</p>
        <h4 className={`articleCardTitle ${styles.title}`}>{title}</h4>
      </article>
    </Link>
  )
}

export default ArticleCard
