import React from "react"
import * as R from "ramda"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import * as styles from "./premiseMapNaantali.module.scss"
// import Button from '@material-ui/core/Button';

import Power from "../images/power.svg"
import LSJH from "../images/LSJH-logo.png"

const PremiseMapNaantali = ({ pageContent }) => {
  const map = R.path(["map", "gatsbyImageData"], pageContent)

  const mapItems = [
    {
      url: "/naantali-2022/kohteet/1-koti-onni-veke",
      number: "1",
      name: "1. Koti Onni / Veke",
      img: "https://images.ctfassets.net/ksssf3t869cm/539EhPDL91R0KTivm17rGN/00578a778f9f1ae8f0b70b2c5c9e021c/Koti_Onni_.jpg?w=400&h=213&q=80&fm=webp",
      pos: {
        top: "14.8",
        right: "65"
      }
    },
    {
      url: "/naantali-2022/kohteet/2-koti-onni-veke",
      number: "2",
      name: "2. Koti Onni / Veke",
      img: "https://images.ctfassets.net/ksssf3t869cm/539EhPDL91R0KTivm17rGN/00578a778f9f1ae8f0b70b2c5c9e021c/Koti_Onni_.jpg?w=400&h=213&q=80&fm=webp",
      pos: {
        top: "11.3",
        right: "57.5",
      }
    },
    {
      url: "/naantali-2022/kohteet/3-honka-haiku",
      number: "3",
      name: "3. Honka Haiku",
      img: "https://images.ctfassets.net/ksssf3t869cm/6WB99n1DcVMf63ql9dk6hL/119986d99a6762ffcb8fcaec21c1ba71/HonkaHaiku.jpg?w=400&h=301&q=80&fm=webp",
      pos: {
        top: "15.8",
        right: "44",
      }
    },
    {
      url: "/naantali-2022/kohteet/4-kannustalon-auma",
      number: "4",
      name: "4. Kannustalon Auma",
      img: "https://images.ctfassets.net/ksssf3t869cm/2LNZb6HyQAZIK6obmq0GyQ/e83205b4d109bac6120fc6da65054650/Kannustalo.jpg?w=400&h=283&q=80&fm=webp",
      pos: {
        top: "25.8",
        right: "41.6",
      }
    },
    {
      url: "/naantali-2022/kohteet/7-hirsi-kastelli-aisti",
      number: "7",
      name: "7. Hirsi-Kastelli Aisti",
      img: "https://images.ctfassets.net/ksssf3t869cm/408APvMmbe3QCWGZ9qSJjg/860a7cab5d17e8965bb76faef0d9c29d/Hirsi_Kastelli.jpg?w=400&h=400&q=80&fm=webp",
      pos: {
        top: "18",
        right: "32",
      }
    },
    {
      url: "/naantali-2022/kohteet/8-betoniviidakko",
      number: "8",
      name: "8. Betoniviidakko",
      img: "https://images.ctfassets.net/ksssf3t869cm/6vGdtU38HYxdc9dpLvaDD2/3eef46a6b2a5f2b5976a9febbd0fe4d0/Betoniviidakko.jpg?w=400&h=225&q=80&fm=webp",
      pos: {
        top: "30",
        right: "34",
      }
    },
    {
      url: "/naantali-2022/kohteet/9-auroran-ruusu",
      number: "9",
      name: "9. Auroran Ruusu",
      img: "https://images.ctfassets.net/ksssf3t869cm/6jjCNbH3di9iLtfm74rmJy/70933d15f1b96ffbf1b88a37370d4273/AuroranRuusuDEN.jpg?w=400&h=225&q=80&fm=webp",
      pos: {
        top: "21.5",
        right: "26.8",
      }
    },
    {
      url: "/naantali-2022/kohteet/10-villa-aalto",
      number: "10",
      name: "10. Villa Aalto",
      img: "https://images.ctfassets.net/ksssf3t869cm/3RMC6xZImOpI0b9rpEydAG/c8e8030104ea08cd848df2fad23d3493/AinoaVillaAalto.jpg?w=400&h=225&q=80&fm=webp",
      pos: {
        top: "33.5",
        right: "28",
      }
    },
    {
      url: "/naantali-2022/kohteet/11-kastelli-kultaranta",
      number: "11",
      name: "11. Kastelli Kultaranta",
      img: "https://images.ctfassets.net/ksssf3t869cm/757tT5ggIN6OtararaYtKk/5452c061681280f69669570265bd647b/kastelli-kultaranta.jpg?w=400&h=300&q=80&fm=webp",
      pos: {
        top: "25",
        right: "21.3",
      }
    },
    {
      url: "/naantali-2022/kohteet/12-talo-pukkila",
      number: "12",
      name: "12. Talo Pukkila",
      img: "https://images.ctfassets.net/ksssf3t869cm/21slzGqj2YIXkkcbyom0DZ/0e0e55d66213d0cca544d9d284e9dd6f/Talo_Pukkila.jpg?w=400&h=283&q=80&fm=webp",
      pos: {
        top: "36.8",
        right: "22.8",
      }
    },
    {
      url: "/naantali-2022/kohteet/14-villa-myotatuuli",
      number: "14",
      name: "14. Villa Myötätuuli",
      img: "https://images.ctfassets.net/ksssf3t869cm/5o4UnXMOE3uOh6hohJYTA2/6b0cb6fc490606f923ff426e6c4a98dc/villa-my__t__tuuli.jpg?w=400&h=283&q=80&fm=webp",
      pos: {
        top: "40.6",
        right: "18.5",
      }
    },
    {
      url: "/naantali-2022/kohteet/15-wing",
      number: "15",
      name: "15. Wing",
      img: "https://images.ctfassets.net/ksssf3t869cm/4jNwMyYYgoJeJ1Yqd5Lwed/7b2a40469918e935007b2b44d2f7eb12/HonkatalotWing.jpg",
      pos: {
        top: "35",
        right: "11",
      }
    },
    {
      url: "/naantali-2022/kohteet/16-villa-nade",
      number: "16",
      name: "16. Villa Nåde",
      img: "https://images.ctfassets.net/ksssf3t869cm/6s1uliW1YF1DrLtnrYt8Rl/46be93512068d08ce039f050509a10b3/KontioSkyHouseVillaN__de.jpg?w=400&h=283&q=80&fm=webp",
      pos: {
        top: "72.5",
        right: "51.4",
      }
    },
    {
      url: "/naantali-2022/kohteet/19-torppa-rauhala",
      number: "19",
      name: "19. Torppa Rauhala",
      img: "https://images.ctfassets.net/ksssf3t869cm/1ntrdncVWrIwFiVFni6Y3m/66cc36c616c1548eea69be68b6a9b5f9/LammiTorppaRauhala.jpg?w=400&h=400&q=80&fm=webp",
      pos: {
        top: "60",
        right: "33",
      }
    },
    {
      url: "/naantali-2022/kohteet/20-neljas-tiira",
      number: "20",
      name: "20. Neljäs Tiira",
      img: "https://images.ctfassets.net/ksssf3t869cm/2vIQMqNchKDWJLLvBbVupQ/0a969afde89cdc8f6eff666305bded93/20Tiira.jpg?w=400&h=251&q=80&fm=webp",
      pos: {
        top: "66",
        right: "42.4",
      }
    },
    {
      url: "/naantali-2022/kohteet/21-lounatuulen-tiira",
      number: "21",
      name: "21. Lounatuulen Tiira",
      img: "https://images.ctfassets.net/ksssf3t869cm/2vIQMqNchKDWJLLvBbVupQ/0a969afde89cdc8f6eff666305bded93/20Tiira.jpg?w=400&h=251&q=80&fm=webp",
      pos: {
        top: "65.5",
        right: "48.4",
      }
    },
    {
      url: "/naantali-2022/kohteet/22-casa-los-pinos",
      number: "22",
      name: "22. Casa los Pinos",
      img: "https://images.ctfassets.net/ksssf3t869cm/2vIQMqNchKDWJLLvBbVupQ/0a969afde89cdc8f6eff666305bded93/20Tiira.jpg?w=400&h=251&q=80&fm=webp",
      pos: {
        top: "62",
        right: "55.4",
      }
    },
    {
      url: "/naantali-2022/kohteet/23-rauhalanrinne",
      number: "23",
      name: " 23. Rauhalanrinne",
      img: "https://downloads.ctfassets.net/ksssf3t869cm/m0GnyYck6BnbhwOUqdQPB/2d3ebc9f7c8d13ef83f5d76090b0d6b2/Rauhalanrinne.jpg?w=400&h=600&q=80&fm=webp",
      pos: {
        top: "53.5",
        right: "54.4",
      }
    },
    {
      url: "/naantali-2022/kohteet/28-naantalin-aurinkolinna",
      number: "28",
      name: "28. Naantalin Aurinkolinna",
      img: "https://images.ctfassets.net/ksssf3t869cm/7CHxTLsIWnISmLf9AWZKmw/6eae81e2bd1fad7f5e7535848c2cdb90/Aurinkolinna.jpeg?w=400&h=225&q=80&fm=webp",
      pos: {
        top: "43.5",
        right: "74.4",
      }
    },
    {
      url: "/naantali-2022/kohteet/29-koti-neulanen",
      number: "29",
      name: "29. Koti Neulanen",
      img: "https://images.ctfassets.net/ksssf3t869cm/5HXqMYMFOYQjJNUplRjx7x/a5c1390ad62caaab214dd0be9d5c0160/koti-neulanen.PNG?w=400&h=238&q=80&fm=webp",
      pos: {
        top: "37.5",
        right: "77.4",
      }
    },
    {
      url: "/naantali-2022/kohteet/30-as-oy-lounakallio",
      number: "30",
      name: "30. As Oy Lounakallio",
      img: "https://images.ctfassets.net/ksssf3t869cm/puJUJG3tNOviimL29Lqn8/554bcbc4c20a1ab9f7d51826da036502/Lounakallio.jpg?w=400&h=225&q=80&fm=webp",
      pos: {
        top: "29.5",
        right: "79.4",
      }
    },
    {
      url: "/naantali-2022/kohteet/31-as-oy-lounakallio",
      number: "31",
      name: "31. As Oy Lounakallio",
      img: "https://images.ctfassets.net/ksssf3t869cm/puJUJG3tNOviimL29Lqn8/554bcbc4c20a1ab9f7d51826da036502/Lounakallio.jpg?w=400&h=225&q=80&fm=webp",
      pos: {
        top: "23.5",
        right: "77.4",
      }
    },
    {
      url: "/naantali-2022/kohteet/32-a-lounatuulen-metsa",
      number: "32a",
      name: "32 a. Lounatuulen Metsä",
      img: "https://images.ctfassets.net/ksssf3t869cm/37ebpxuOYvsDCvkc7fhHCV/40b2007e0b6fc65a1a7243c51bb6aa91/Lounatuuli32A__1_.jpg?w=400&h=187&q=80&fm=webp",
      pos: {
        top: "30.2",
        right: "69.5",
      }
    },
    {
      url: "/naantali-2022/kohteet/32-b-lounatuulen-ateljee",
      number: "32b",
      name: "32 b. Lounatuulen Ateljee",
      img: "https://images.ctfassets.net/ksssf3t869cm/4VRtRl2FuJvJyEOIZhbXOC/e6911d39f80f0416e84874defbd4e697/Lounatuuli32B.jpg",
      pos: {
        top: "29",
        right: "64.5",
      }
    },
    {
      url: "/naantali-2022/kohteet/33-lounatuulen-loyly",
      number: "33",
      name: "33. Lounatuulen Löyly",
      img: "https://images.ctfassets.net/ksssf3t869cm/7pvh0JT0Zh2VsX0UEhfYSi/7f689dce90a6d6d001026d458129245a/lounatuulen-l__yly.jpg?w=400&h=225&q=80&fm=webp",
      pos: {
        top: "32",
        right: "60",
      }
    },
    {
      url: "/naantali-2022/kohteet/34-1koti-isokoskelo",
      number: "34",
      name: "34. 1koti IsoKoskelo",
      img: "https://images.ctfassets.net/ksssf3t869cm/3eqReI90pSnKlPmPXmh8sT/2da0344ca847e7d5c8edeac3ef2d021a/Isokoskelo.jpeg?w=400&h=225&q=80&fm=webp",
      pos: {
        top: "38.4",
        right: "41.1",
      }
    },
    {
      url: "/naantali-2022/kohteet/35-1koti-isokoskelo",
      number: "35",
      name: "35. 1Koti IsoKoskelo",
      img: "https://images.ctfassets.net/ksssf3t869cm/3eqReI90pSnKlPmPXmh8sT/2da0344ca847e7d5c8edeac3ef2d021a/Isokoskelo.jpeg?w=400&h=225&q=80&fm=webp",
      pos: {
        top: 43.5,
        right: "33.4",
      }
    },
  ]

  return (
    <section className={styles.premiseMap}>
      <header className={styles.premiseMapHeader}>
        <h2>Messualueen kartta</h2>
        <p>Kurkista sisään haluaamaasi messukotiin kartan linkeistä klikkaamalla.</p>
      </header>

      <div id="map" className={styles.premiseMapContainer}>
        <GatsbyImage image={map} className={styles.premiseMapImage} />
        <ul className={styles.premiseMapLinks}>
          {mapItems.map((item) => 
            (
              <li>
                <Link 
                  className={styles.tooltipContainer}
                  to={item.url}
                  style={{top: item.pos.top + "%", right: item.pos.right + "%"}}
                  >
                  <span>{item.number}</span>
                  <div className={styles.tooltip}>
                    {item.name}
                    <div className="relative h-0 pb-[40%] md:pb-[56.25%] mb-4 mt-5">
                      <img class="object-cover w-full h-full !absolute top-0 left-0" src={item.img} alt="" />
                    </div>
                  </div>
                </Link>
              </li>
            )
          )}
          <li className={styles.pinkLink + " " + styles.aly}>
            <a className={styles.tooltipContainer} target="_blank" href="https://www.naantalinenergia.fi/fin/yritys/asuntomessut_2022/?utm_source=app&utm_medium=map&utm_campaign=naantalinaurinkoenergiaa">
              <span>ÄLY</span>
              <div>
                <div className={styles.tooltip}>Naantalin Energian älymuuntamo ja katolle integroidut aurinkopaneelit
                  <img class="mt-4" src="https://images.ctfassets.net/ksssf3t869cm/7vxR4jq1m9vGLZG3uBmKAU/bd959b2839384c68a663d2e94e3c0e7f/20220609_Naantalin-Energia_Virtuaaliset-Asuntomessut_1920x1080px_kuva3.jpg?w=400&h=225&q=80&fm=webp" alt="" /></div>
              </div>
            </a>
          </li>
          <li className={styles.whiteLink + " " + styles.aurinkopaneelit}>
            <a className={styles.tooltipContainer} target="_blank" href="https://www.naantalinenergia.fi/fin/yritys/asuntomessut_2022/?utm_source=app&utm_medium=map&utm_campaign=naantalinaurinkoenergiaa">
              <span>
                <img
                  src={Power}
                  className="power absolute top-2 left-2 z-30"
                  alt=""
                />
              </span>
              <div>
                <div className={styles.tooltip}>Naantalin Energian toimittamat aurinkopaneelit
                  <img class="mt-4" src="https://images.ctfassets.net/ksssf3t869cm/7Iu8rMNdJRCdQALkBABG5f/5aeae3f4ded57fed684bc88cc8660743/20220609_Naantalin-Energia_Virtuaaliset-Asuntomessut_1920x1080px_kuva2.jpg?w=400&h=225&q=80&fm=webp" alt="" /></div>
              </div>
            </a>
          </li>
          <li className={styles.whiteLink + " " + styles.aurinkopaneelit2}>
            <Link className={styles.tooltipContainer} to="https://www.naantalinenergia.fi/fin/yritys/asuntomessut_2022/?utm_source=app&utm_medium=map&utm_campaign=naantalinaurinkoenergiaa">
              <span>
                <img
                  src={Power}
                  className="power absolute top-2 left-2 z-30"
                  alt=""
                />
              </span>
              <div>
                <div className={styles.tooltip}>Naantalin Energian aurinkovoimala
                  <img class="mt-4" src="https://images.ctfassets.net/ksssf3t869cm/7JdmO8ywO65cGRbqUz2uD4/6fdb7f0b2a0376f38d05e24501e2611a/20220609_Naantalin-Energia_Virtuaaliset-Asuntomessut_1920x1080px_kuva1.jpg?w=400&h=225&q=80&fm=webp" alt="" /></div>
              </div>
            </Link>
          </li>
          <li className={styles.whiteLink + " " + styles.LSJH}>
            <a className={styles.tooltipContainer} target="_blank" href="https://www.lsjh.fi/fi/neuvonta/biojatteen-lajittelu-on-helppoa/">
              <span>
                <img
                  src={LSJH}
                  className="absolute top-0 left-0 z-30"
                  alt=""
                />
              </span>
              <div>
                <div className={styles.tooltip}>Lounais-Suomen Jätehuolto Oy
                  <img class="mt-4" src={LSJH} alt="" /></div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default PremiseMapNaantali
