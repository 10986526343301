import React from "react"
import * as R from "ramda"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import ReactPlayer from "react-player"
import Box from "@material-ui/core/Box"
import * as styles from "./videoLift.module.scss"

const VideoLift = ({ pageContent }) => {
  const url = R.path(["videoUrl"], pageContent)
  const thumbNail = R.path(["thumbNail", "file", "url"], pageContent)
  const title = R.path(["title"], pageContent)
  const contentText = R.path(["contentText", "contentText"], pageContent)
  const buttonAction = R.path(["buttonSlug"], pageContent)
  const buttonText = R.path(["ctaButtonText"], pageContent)
  const type = R.path(["ctaButtonAction", "__typename"], pageContent)

  let prefix = ""

  if (type === "ContentfulKategoriasivu") {
    prefix = ""
  }
  if (type === "ContentfulArtikkelisivu") {
    prefix = "artikkelit/"
  }
  if (type === "ContentfulKohdesivu") {
    prefix = "kohteet/"
  }

  return (
    <Box id={`${url}`} className={styles.videoLift}>
      <div className={styles.videoLiftTextContainer}>
        <h2 className="videoLiftTitle">{title}</h2>
        <p>{contentText}</p>
      </div>
      <ReactPlayer
        width="100%"
        className={styles.videoPlayer}
        url={url}
        light={thumbNail}
      />
      <div className={styles.ctabuttoncontainerflex}>
        <Link
          className={`${styles.ctabuttoncontainer}`}
          to={`/${prefix}${buttonAction}`}
          style={{ backgroundColor: "#69d2fa" }}
        >
          <Button variant="contained" color="primary">
            {buttonText}
          </Button>
        </Link>
      </div>
    </Box>
  )
}

export default VideoLift
