import React from "react"
import * as R from 'ramda'
import { Link } from "gatsby"

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import NavigateNext from '@material-ui/icons/NavigateNext'

const TextLift = ({pageContent, type}) => {

  const title = R.path(['title'], pageContent) || R.path(['name'], pageContent);
  const slug = R.path(['slug'], pageContent);

  // Define url for the button action
  let url = "";
  if(type === 'article'){
    url = `/artikkelit/${slug}`
  } else if(type === 'premise'){
    url = `/kohteet/${slug}`
  } else {
    url = `/${slug}`
  }

  return (
    
    <Box style={{width: '100%'}}>
      <Link to={url} className="textlinklist" style={{width: '100%'}}>
        <Button variant="contained" color="primary">
          <Grid container>
            <Grid item xs={10} style={{
              textAlign: 'left', 
              display: 'flex', 
              alignItems: 'center'
            }}> 
              {title}
            </Grid>
            <Grid item xs={2} style={{flexDirection: 'row',
              alignItems: 'center',
              display: 'flex',
              textAlign: 'right',
              justifyContent: 'flex-end'}}>
              <NavigateNext style={{fontSize: '28px'}} />
            </Grid>
          </Grid>
        </Button>
      </Link>
    </Box>
   
  )
}

export default TextLift