import React from "react"
import * as R from "ramda"
import { Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

import * as styles from "./pictureLift.module.scss"

const PictureLift = ({ pageContent, type }) => {
  const title = R.path(["title"], pageContent) || R.path(["name"], pageContent)
  let category = R.path(["kategoria"], pageContent)
  const contentTextShort = R.path(["contentTextShort"], pageContent)
  const slug = R.path(["slug"], pageContent)
  const mainImage = R.path(["mainImage", "gatsbyImageData"], pageContent)
  let buttonText = ""

  // Define url for the button action
  let url = ""
  if (type === "article" && category === "Sisustuskierros") {
    url = `/artikkelit/${slug}`
    buttonText = "Astu sisään"
  } else if (type === "article" && category !== "Sisustuskierros") {
    url = `/artikkelit/${slug}`
    buttonText = "Lue lisää"
  } else if (type === "premise") {
    url = `/kohteet/${slug}`
    buttonText = "Astu sisään"
  } else {
    url = `/${slug}`
    buttonText = "Lue lisää"
  }

  return (
    <Grid item xs={12} sm={12} md={6} lg className={styles.grid}>
      <Box>
        <GatsbyImage
          image={mainImage}
          style={{ width: "100%", height: "238px" }}
        />
        {(type === "article" || type === "premise") && (
          <p className="categoryText">{category}</p>
        )}
        <h3>{title}</h3>
        <p>{contentTextShort}</p>
        <Link to={url}>
          <Button variant="contained" color="primary">
            {buttonText}
          </Button>
        </Link>
      </Box>
    </Grid>
  )
}

export default PictureLift
