import React from "react"
import * as R from "ramda"
import { Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"
import { useMicroCopyFi } from "../hooks/microCopy"
import GetMicroCopy from "../shared/getMicroCopy"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

import * as styles from "./colorLift.module.scss"

const ColorLift = ({ pageContent }) => {
  // UI texts
  const microCopyTexts = useMicroCopyFi
  const labelArticlesFolder = GetMicroCopy(microCopyTexts, "polkuArtikkelit")
  // end UI texts
  const backgroundColor = R.path(["backgroundColor"], pageContent)
  const title = R.path(["title"], pageContent)
  const contentText = R.path(["contentText", "contentText"], pageContent)
  const buttonText = R.path(["ctaButton1Text"], pageContent)
  const slug = R.path(["ctaButton1Action", "slug"], pageContent)
  const mainImage = R.path(
    ["ctaButton1Action", "mainImage", "gatsbyImageData"],
    pageContent
  )
  const type = R.path(["ctaButton1Action", "__typename"], pageContent)

  let bgColor = ""

  if (backgroundColor === "Vihreä") {
    bgColor = "#55dc73"
  }
  if (backgroundColor === "Keltainen") {
    bgColor = "#fae600"
  }
  if (backgroundColor === "Sininen") {
    bgColor = "#69d2fa"
  }
  if (backgroundColor === "Pinkki") {
    bgColor = "#ff91d2"
  }

  let prefix = ""

  if (type === "ContentfulKategoriasivu") {
    prefix = ""
  }
  if (type === "ContentfulArtikkelisivu") {
    prefix = `${labelArticlesFolder}/`
  }

  return (
    <Box className={styles.box} style={{ backgroundColor: bgColor }}>
      <Grid
        container
        direction="row"
        spacing={0}
        style={{
          width: "100%",
          maxWidth: "1200px",
          height: "100%",
          backgroundColor: "white",
        }}
      >
        <Grid item xs={12} md={6} className={styles.imageContainer}>
          <GatsbyImage image={mainImage} style={{ height: "100%" }} />
        </Grid>
        <Grid item xs={12} md={6} className={styles.textBox}>
          <h2 className="colorLiftTitle">{title}</h2>
          <p>{contentText}</p>

          <Link to={`/${prefix}${slug}`}>
            <Button variant="contained" color="primary">
              {buttonText}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ColorLift
