import React from "react"
import * as R from "ramda"
import { useMicroCopyFi } from "../hooks/microCopy"
import GetMicroCopy from "../shared/getMicroCopy"
import { graphql, Link, useStaticQuery } from "gatsby"
import { BgImage } from "gbimage-bridge"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import * as styles from "./articleLiftups.module.scss"
import ArticleCard from "./articleCard"

const data = graphql`
  {
    shape_lightGreen: file(relativePath: { eq: "shape_lightGreen.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          layout: FULL_WIDTH
        )
      }
    }
  }
`
const ArticleLiftups = ({ pageContent }) => {
  const microCopyTexts = useMicroCopyFi
  const labelArticlesFolder = GetMicroCopy(microCopyTexts, "polkuArtikkelit")
  const { shape_lightGreen } = useStaticQuery(data)
  const articleLiftups = R.path(["articleLiftups"], pageContent)
  const title = R.path(["title"], pageContent)
  const buttonAction = R.path(["buttonAction", "slug"], pageContent)
  const buttonText = R.path(["buttonText"], pageContent)
  const article = articleLiftups ? articleLiftups[0] : null
  const shapeLightGreen = R.path(
    ["childImageSharp", "gatsbyImageData"],
    shape_lightGreen
  )

  const theme = useTheme()
  const mobileSizeDetected = useMediaQuery(theme.breakpoints.down("sm"))
  const gutter = mobileSizeDetected ? "1" : "3"

  // Gatsby background images seem to jump around during rendering. Static backgroundsize helps.
  // The following stuff helps to take care of this problem and defines two sizes for mobile/laptop.
  let shapeColor = shapeLightGreen
  let shapeSize = mobileSizeDetected ? "60px 41px" : "190px 129px"
  let shapeMinHeight = mobileSizeDetected ? "41px" : "190px"
  let titleMarginBottom = mobileSizeDetected ? "-5px" : "20px"
  // let btnColor = '#55dc73'

  return (
    <Box
      component="section"
      style={{
        padding: "0 20px",
        maxWidth: "1240px",
        margin: "85px auto 61px auto",
      }}
    >
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12} md={12} lg={8}>
          <div style={{ marginLeft: "-35px" }}>
            <BgImage
              image={shapeColor}
              style={{
                minHeight: shapeMinHeight,
                backgroundSize: shapeSize,
                backgroundPositionX: "30px",
              }}
            >
              <h2
                style={{
                  wordBreak: "break-word",
                  position: "absolute",
                  bottom: "0px",
                  marginBottom: titleMarginBottom,
                  marginLeft: "35px",
                }}
              >
                {title}
              </h2>
            </BgImage>
          </div>
        </Grid>
      </Grid>

      <Grid
        className={styles.articleLifups}
        container
        direction="row"
        spacing={gutter}
        alignItems="flex-start"
      >
        <Grid className={styles.largeLiftup} item xs={12} md={6}>
          <ArticleCard
            slugPrefix={labelArticlesFolder}
            slug={article?.slug}
            date={article?.createdAt}
            title={article?.title}
            image={article?.mainImage}
          />
        </Grid>

        <Grid item xs={12} md={6} spacing={gutter}>
          <Grid container direction="row" spacing={gutter}>
            {articleLiftups?.slice(1, 9).map((article, index) => {
              return (
                <Grid item xs={12} md={6}>
                  <ArticleCard
                    slugPrefix={labelArticlesFolder}
                    slug={article.slug}
                    date={article.createdAt}
                    title={article.title}
                    image={article.mainImage}
                    key={index}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>

      <div style={{ marginTop: "57px", textAlign: "center" }}>
        <Link to={`/${buttonAction}`} className="blue">
          <Button variant="contained" color="primary">
            {buttonText}
          </Button>
        </Link>
      </div>
    </Box>
  )
}

export default ArticleLiftups
