import React from "react"
import * as R from "ramda"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import * as styles from "./premiseMap.module.scss"
import PremiseMapLohja from "./premiseMapLohja"
import PremiseMapNaantali from "./premiseMapNaantali"
// import Button from '@material-ui/core/Button';

const PremiseMap = ({ pageContent }) => {
  const year = R.path(["premiseYear"], pageContent);

  let mapComponent;
  switch(year) {
    case '2023':
      mapComponent = (
        <div style={{
          margin: '0 auto', 
          display: 'block',
          position: 'relative',
        }}>

        <iframe
          src="https://kiosk.steerpath.com/asuntomessut-loviisa/"
          width="100%"
          height="800"
          margin='auto'
        />

        </div>
      );
      break;
    case '2022':
      mapComponent = <PremiseMapNaantali pageContent={pageContent} />;
      break;
    case '2021':
      mapComponent = <PremiseMapLohja pageContent={pageContent} />;
      break;
    default:
      mapComponent = <div>No map available</div>;
  }

  return <div>{mapComponent}</div>;
}

export default PremiseMap;



/*
import React from "react"
import * as R from "ramda"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import * as styles from "./premiseMap.module.scss"
import PremiseMapLohja from "./premiseMapLohja"
import PremiseMapNaantali from "./premiseMapNaantali"
// import Button from '@material-ui/core/Button';

const PremiseMap = ({ pageContent }) => {
  const year = R.path(["premiseYear"], pageContent);

  return (
    <div>
    {year !== '2022' ? (
      <PremiseMapLohja pageContent={pageContent} />
    ) : (
      <PremiseMapNaantali pageContent={pageContent} />
    )}
    </div>
  )
}

export default PremiseMap
*/