import React from "react"
import * as R from "ramda"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import * as styles from "./premiseMap.module.scss"
// import Button from '@material-ui/core/Button';

const PremiseMapLohja = ({ pageContent }) => {
  const map = R.path(["map", "gatsbyImageData"], pageContent)

  return (
    <section className={styles.premiseMap}>
      <header className={styles.premiseMapHeader}>
        <h2>Kurkista koteihin</h2>
        <p>Siirry kartasta suoraan kohteeseen linkkiä painamalla.</p>

        {/* <div style={{ marginTop: '35px', textAlign: 'center' }}>
          <Link to="/lohja-2021" className="blue">
            <Button variant="contained" color="primary">
              Katso kaikki kohteet
            </Button>
          </Link>
        </div> */}
      </header>

      <div id="map" className={styles.premiseMapContainer}>
        <GatsbyImage image={map} className={styles.premiseMapImage} />
        <ul className={styles.premiseMapLinks}>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/1-villa-ilta"
            >
              <span>1</span>
              <div className={styles.tooltip}>Kurkista Villa Iltaan</div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/2-promodi"
            >
              <span>2</span>
              <div>
                <div className={styles.tooltip}>Kurkista Promodiin</div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/3-kannustalo-suomi"
            >
              <span>3</span>
              <div>
                <div className={styles.tooltip}>
                  Kurkista Kannustalo Suomeen
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/4-kastelli-veistos"
            >
              <span>4</span>
              <div>
                <div className={styles.tooltip}>
                  Kurkista Kastellin Veistokseen
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/5-lohjan-saarenta"
            >
              <span>5</span>
              <div>
                <div className={styles.tooltip}>Kurkista Lohjan Saarentaan</div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/6-kastelli-plazia-jarven-sydan"
            >
              <span>6</span>
              <div>
                <div className={styles.tooltip}>
                  Kurkista Kastelli Plazia Järven sydämeen
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021//kohteet/7-pyorre-talo"
            >
              <span>7</span>
              <div>
                <div className={styles.tooltip}>Kurkista Pyörre-taloon</div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/8-talo-sensu"
            >
              <span>8</span>
              <div>
                <div className={styles.tooltip}>Kurkista Talo Sensuun</div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/9-villa-nordic-stories"
            >
              <span>9</span>
              <div>
                <div className={styles.tooltip}>
                  Kurkista Villa Nordic Storiesiin
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/10-joutsela"
            >
              <span>10</span>
              <div>
                <div className={styles.tooltip}>Kurkista Joutselaan</div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/11-ormax-aurinko-hiidenkoti"
            >
              <span>11</span>
              <div>
                <div className={styles.tooltip}>
                  Kurkista Ormax Aurinko Hiidenkotiin
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/12-masku-hiidenkoti"
            >
              <span>12</span>
              <div>
                <div className={styles.tooltip}>
                  Kurkista Masku Hiidenkotiin
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/13-jalava"
            >
              <span>13</span>
              <div>
                <div className={styles.tooltip}>Kurkista Jalavaan</div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/14-dekotalo-kuulas"
            >
              <span>14</span>
              <div>
                <div className={styles.tooltip}>
                  Kurkista Dekotalo Kuulakseen
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/15-ainoa-fiksu"
            >
              <span>15</span>
              <div>
                <div className={styles.tooltip}>Kurkista Ainoa Fiksuun</div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/16-villa-idyll"
            >
              <span>16</span>
              <div>
                <div className={styles.tooltip}>Kurkista Villa Idyliin</div>
              </div>
            </Link>
          </li>
          <li className={styles.noLink}>
            <Link className={styles.tooltipContainer} to="">
              <span>17</span>
              <div>
                <div className={styles.tooltip}>Hoivatilat</div>
              </div>
            </Link>
          </li>
          <li className={styles.noLink}>
            <Link className={styles.tooltipContainer} to="">
              <span>18</span>
              <div>
                <div className={styles.tooltip}>Hoivatilat</div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/19-lova-saunaosasto-ja-kattoterassi"
            >
              <span>19</span>
              <div>
                <div className={styles.tooltip}>
                  Kurkista Lovan saunaosastoon ja kattoterassiin
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/20-lova-masku"
            >
              <span>20</span>
              <div>
                <div className={styles.tooltip}>Kurkista Lova Maskuun</div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/21-lova-luksia"
            >
              <span>21</span>
              <div>
                <div className={styles.tooltip}>Kurkista Lova Luksiaan</div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/22-ta-asumisoikeus-seilori"
            >
              <span>22</span>
              <div>
                <div className={styles.tooltip}>
                  Kurkista TA-Asumisoikeus Seiloriin
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/23-ta-asumisoikeus-colori"
            >
              <span>23</span>
              <div>
                <div className={styles.tooltip}>
                  Kurkista TA-Asumisoikeus Coloriin
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/24-ta-asumisoikeus-talosauna-osasto-ja-kattoterassi"
            >
              <span>24</span>
              <div>
                <div className={styles.tooltip}>
                  Kurkista TA-Asumisoikeuden talosauna-osastooon ja
                  kattoterassiin
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/25-villa-kokkokallio"
            >
              <span>25</span>
              <div>
                <div className={styles.tooltip}>
                  Kurkista Villa Kokkokallioon
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className={styles.tooltipContainer}
              to="/lohja-2021/kohteet/26-villa-hopeapuu"
            >
              <span>26</span>
              <div>
                <div className={styles.tooltip}>Kurkista Villa Hopeapuuhun</div>
              </div>
            </Link>
          </li>
          <li className={styles.noLink}>
            <Link className={styles.tooltipContainer} to="">
              <span>27</span>
              <div>
                <div className={styles.tooltip}>Minitalot</div>
              </div>
            </Link>
          </li>
          <li className={styles.noLink}>
            <Link className={styles.tooltipContainer} to="">
              <span>28</span>
              <div>
                <div className={styles.tooltip}>Minitalot</div>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default PremiseMapLohja
