import React from "react"
import * as R from "ramda"

import LiftList from "./liftList"
import ColorLift from "./colorLift"
import VideoLift from "./videoLift"
import VerticalTextLiftList from "./verticalTextLiftList"
import ContentElement from "./contentElement"
import ArticleLiftups from "./articleLiftups"
import TextElement from "./textElement"
import PremiseMap from "./premiseMap"
import AnimatedLifts from "../components/animatedLifts"

const getElement = section => {
  if (R.path(["__typename"], section) === "ContentfulNostolista") {
    return <LiftList pageContent={section} key={R.path(["title"], section)} />
  }
  if (R.path(["__typename"], section) === "ContentfulTekstilinkkilista") {
    return (
      <VerticalTextLiftList
        pageContent={section}
        key={R.path(["title"], section)}
      />
    )
  }
  if (R.path(["__typename"], section) === "ContentfulVarinosto") {
    return (
      <div>
        <ColorLift pageContent={section} key={R.path(["title"], section)} />
      </div>
    )
  }
  if (R.path(["__typename"], section) === "ContentfulSisaltoelementti") {
    return (
      <div>
        <ContentElement
          pageContent={section}
          key={R.path(["title"], section)}
        />
      </div>
    )
  }
  if (R.path(["__typename"], section) === "ContentfulVideonosto") {
    return (
      <div>
        <VideoLift pageContent={section} key={R.path(["title"], section)} />
      </div>
    )
  }
  if (R.path(["__typename"], section) === "ContentfulArtikkelinostot") {
    return (
      <div>
        <ArticleLiftups
          pageContent={section}
          key={R.path(["title"], section)}
        />
      </div>
    )
  }
  if (R.path(["__typename"], section) === "ContentfulTekstikentta") {
    return (
      <div>
        <TextElement pageContent={section} key={R.path(["title"], section)} />
      </div>
    )
  }
  if (R.path(["__typename"], section) === "ContentfulKohdekartta") {
    return (
      <div>
          <PremiseMap pageContent={section} key={R.path(["title"], section)} />
      </div>
    )
  }
  if (R.path(["__typename"], section) === "ContentfulAnimoidutNostot") {
    return (
      <div>
        <AnimatedLifts pageContent={section} key={R.path(["title"], section)} />
      </div>
    )
  }
}

const LiftContent = ({ pageContent }) => {
  const references = R.path(["virtualLiftContent"], pageContent)
    ? R.path(["virtualLiftContent"], pageContent)
    : R.path(["liftContent"], pageContent)

  return references
    ? !Array.isArray(references)
      ? getElement(references)
      : references.map(reference => getElement(reference))
    : null
}

export default LiftContent
