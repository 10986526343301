import React from "react"
import * as R from "ramda"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import * as styles from "./verticalTextLiftList.module.scss"

import VerticalTextLift from "./verticalTextLift"

const VerticalTextLiftList = ({ pageContent }) => {
  const title = R.path(["title"], pageContent)
  const contentText = R.path(["contentText", "contentText"], pageContent)
  const lifts = R.path(["liftList"], pageContent)

  return (
    <Box className={styles.verticalLift}>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12} md={5}>
          <h2 className="colorLiftTitle">{title}</h2>
          <p>{contentText}</p>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid
            container
            spacing={0}
            direction="column"
            className={styles.container}
          >
            <VerticalTextLift liftContent={lifts} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default VerticalTextLiftList
